/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { loadRefreshList } from '@/assets/app.js';
import { ListTheme, ListOther } from 'vue-ydui';
import { RxUtil } from "@/assets/util.js";
import listMore from '@/components/common/listMore';
Vue.component(listMore.name, listMore);
export default {
  name: 'my-draft',
  data() {
    return {
      list: [],
      pageIndex: 0,
      pageSize: 20,
      total: 0,
      fixed: true
    };
  },
  created() {
    this.loadList(true, false);
  },
  methods: {
    refreshList() {
      this.loadList(true, false);
    },
    loadList(initPage, merge) {
      var url = _baseUrl + "/mobile/bpm/getMyDrafts.do";
      if (initPage) {
        this.pageIndex = 0;
      } else {
        this.pageIndex++;
      }
      var params = "pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
      loadRefreshList(url, params, this, data => {
        this.total = data.total;
        console.log(data);
        RxUtil.setCache("busad", data.rowList[0].busKey);
      }, merge);
    },
    getFirstLetter(word) {
      return '草稿'; //word.substr(0,1).toUpperCase();
    },

    onCGpage(item) {
      console.log(item.treeName);
      switch (item.treeName) {
        case "合同审批":
          this.$router.push({
            name: "lcfqHtsp",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "环境控制目标汇总记录":
          this.$router.push({
            name: "lcfqMbhzjl",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "人员事假请假":
          this.$router.push({
            name: "lcfqSjqj",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "人员日常请假":
          this.$router.push({
            name: "lcfqPtqj",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "设备期间核查计划":
          this.$router.push({
            name: "lcfqQjhc",
            params: {
              solId: item.solId,
              instId: item.instId,
              busad: item.busKey
            }
          });
          break;
        case "设备作废、停用、降级申请":
          this.$router.push({
            name: "lcfqSbsq",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "人员监督申请":
          this.$router.push({
            name: "lcfqNljd",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "人员监督总计划":
          this.$router.push({
            name: "lcfqRyjdz",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "人员培训申请":
          this.$router.push({
            name: "lcfqRypx",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "人员培训总计划":
          this.$router.push({
            name: "lcfqRypxz",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "改进计划审批":
          this.$router.push({
            name: "lcfqGjjh",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "改进总计划":
          this.$router.push({
            name: "lcfqGjzjh",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "风险计划":
          this.$router.push({
            name: "lcfqFxjh",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "风险总计划":
          this.$router.push({
            name: "lcfqFxzjh",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "监控总计划":
          this.$router.push({
            name: "lcfqJkzjh",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "监控结果有效性计划":
          this.$router.push({
            name: "lcfqYxjh",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "文件公示记录单":
          this.$router.push({
            name: "lcfqGsjl",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "错误更改申请流程":
          this.$router.push({
            name: "lcfqCwggsq",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "公文发文":
          this.$router.push({
            name: "lcfqGwfw",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "用车申请":
          this.$router.push({
            name: "lcfqPcsq",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "车辆维修保养申请":
          this.$router.push({
            name: "lcfqBywx",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "办公及固定资产采购申请":
          //用品采购
          this.$router.push({
            name: "lcfqYpcg",
            params: {
              solId: item.solId,
              instId: item.instId,
              busad: item.busKey
            }
          });
          break;
        case "服务与供用品采购":
          this.$router.push({
            name: "lcfqFwhgypcg",
            params: {
              solId: item.solId,
              busad: item.busKey,
              instId: item.instId
            }
          });
          break;
        case "会议室使用申请":
          this.$router.push({
            name: "lcfqHys",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "普通请假单":
          this.$router.push({
            name: "lcfqPtqj",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "事假请假单":
          this.$router.push({
            name: "lcfqSjqj",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "年休请假单":
          this.$router.push({
            name: "lcfqNxqj",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "人员入职申请流程":
          this.$router.push({
            name: "lcfRyrz",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "人员调动":
          this.$router.push({
            name: "lcfqRydd",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "人员离职":
          this.$router.push({
            name: "lcfqRylz",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "用车申请":
          this.$router.push({
            name: "lcfqYcsq",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "采购清单":
          // this.$router.push({
          // 	name: "lcfqYpcg",
          // 	params:{solId:item.solId,instId:item.instId}
          // });
          this.$dialog.confirm({
            title: '请往pc端查看发文草稿',
            opts: [{
              txt: '返回',
              color: "#25c4b5"
            }]
          });
          break;
        case "用印启用":
          this.$router.push({
            name: "lcfqYyqy",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "用印审批":
          this.$router.push({
            name: "lcfqYysp",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "用印移交":
          this.$router.push({
            name: "lcfqYyyj",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        // case "用印封存":
        // 	this.$router.push({
        // 		name: "lcfqYzfc",
        // 		params:{solId:item.solId,instId:item.instId}
        // 	});
        // 	break;
        case "期间核查审核":
          this.$router.push({
            name: "lcfqQjhc",
            params: {
              solId: item.solId,
              busad: item.busKey
            }
          });
          break;
        case "车辆维修":
          this.$router.push({
            name: "lcfqBywx",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "来文传阅":
          this.$router.push({
            name: "lcfqFw",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "发文（一般通知）":
          this.$router.push({
            name: "lcfqFw",
            params: {
              solId: item.solId,
              instId: item.instId
            }
          });
          break;
        case "发文":
          this.$dialog.confirm({
            title: '请往pc端查看发文草稿',
            opts: [{
              txt: '返回',
              color: "#25c4b5"
            }]
          });
          break;
        default:
          this.$router.push({
            name: "startForm",
            params: {
              solId: item.solId,
              instId: item.instId,
              from: 'myDrafts'
            }
          });
          break;
      }
    }
  }
};